<template>
    <div>
        <TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
            :slot_table_list="['operation','notify_status','is_read','sign_status']" ref="list" :rowKey="'id'" 
			:scroll="{x:2000}">
            <template slot="right_btn">
                <a :href="'/manage/crm/outbound_order_export' | export_form" target="_blank">
                    <a-button class="a_btn">导出</a-button>
                </a>
            </template>
            <template slot="notify_status" slot-scope="data">
                <span>{{data.record.notify_status==1?'已推送':'未推送'}}</span>
            </template>
            <template slot="is_read" slot-scope="data">
                <span v-if="data.record.is_read==0">全部未读</span>
                <span v-if="data.record.is_read==1">部分已读</span>
                <span v-if="data.record.is_read==2">全部已读</span>
            </template>
            <template slot="sign_status" slot-scope="data">
                <span>{{data.record.sign_status==1?'已签字':'未签字'}}</span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="transfer(data.record)">查看</a>
                <a-divider type="vertical" v-if="data.record.recordnotify_status ==0" />
                <a @click="edit(data.record)" v-if="data.record.notify_status ==0"> 推送</a>
            </template>
        </TableList>
        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible"></EditPop>
        <EditPop @handleSubmit="transfer_submit" :form_data="transfer_form_data" :visible.sync="transfer_visible">
        </EditPop>
    </div>
</template>
<script>
    // @ is an alias to /src
    import EditPop from "@/components/EditPop";
    import Form from "@/components/Form";
    import TableList from "@/components/TableList";
    import {
        outbound_order_index,
        outbound_order_push,
        outbound_order_export
    } from '@/api/push'
    const columns = [{
            title: "订货单号",
            dataIndex: "orderId",
        },
        {
            title: "出库单号",
            dataIndex: "outcomeId",
        },
        {
            title: "出货仓库",
            dataIndex: "outcomeStorage",
        },
        {
            title: "销售单位",
            dataIndex: "sellerName",
        },
        {
            title: "服务区域",
            dataIndex: "department",
        },
        {
            title: "收货人",
            dataIndex: "consignee",
        },
        {
            title: "收货人电话",
            dataIndex: "sellerPhone",
        },
        {
            title: "经销商对应主管",
            dataIndex: "name",
        },
        {
            title: "出库单类型",
            dataIndex: "bill_type",
        },
        {
            title: "出库单状态",
            scopedSlots: {
                customRender: "notify_status"
            }
        },
        {
            title: "阅读状态",
            scopedSlots: {
                customRender: "is_read"
            }
        },
        {
            title: "签字状态",
            scopedSlots: {
                customRender: "sign_status"
            }
        },
        {
            title: "出库单生成时间",
            dataIndex: "create_time",

        },
        {
            title: "操作",
            scopedSlots: {
                customRender: "operation"
            }
        }
    ];

    export default {
        name: "Index",
        components: {
            EditPop,
            Form,
            TableList
        },
        data() {
            return {
                get_table_list: outbound_order_index,
                columns,
                visible: false,
                form_data_seo: {
                    ...this.$config.form_data_seo,
                    list: [

                        {
                            type: "text",
                            name: "username",
                            title: "姓名",
                            placeholder: "请输入收货人姓名",
                            options: {}
                        },
                        {
                            type: "text",
                            name: "ordersn",
                            title: "订货单号",
                            placeholder: "请输入订货单号",
                            options: {}
                        },
                        {
                            type: "text",
                            name: "outcomeId",
                            title: "出库单号",
                            placeholder: "请输入出库单号",
                            options: {}
                        },
                        {
                            type: "text",
                            name: "organization_name",
                            title: "销售单位",
                            placeholder: "请输入销售单位",
                            options: {}
                        },
                        {
                            type: "tree-select",
                            name: "department_id",
                            title: "服务区域",
                            options: {},
                            treeData: [],
                            multiple: false
                        },
                        {
                            type: "select",
                            name: "status",
                            list: [{
                                    key: 1,
                                    value: '未推送'
                                },
                                {
                                    key: 2,
                                    value: '已推送'
                                },
                            ],
                            title: "出库单状态",
                            options: {}
                        },
                        {
                            type: "select",
                            name: "type",
                            list: [{
                                    key: 1,
                                    value: '桶装'
                                },
                                {
                                    key: 2,
                                    value: '瓶装'
                                },
                            ],
                            title: "出库单类型",
                            options: {}
                        },
                        {
                            type: "select",
                            name: "is_read",
                            list: [{
                                    key: 1,
                                    value: '未阅读'
                                },
                                {
                                    key: 2,
                                    value: '已阅读'
                                },


                            ],
                            title: "阅读状态",
                            options: {}
                        },
                        {
                            type: "select",
                            name: "sign_status",
                            list: [{
                                    key: 1,
                                    value: '已签字'
                                },
                                {
                                    key: 2,
                                    value: '未签字'
                                },
                            ],
                            title: "签字状态",
                            options: {}
                        },
                        {
                            type: "range_date",
                            name: "range_date1",
                            title: "出库单生成时间",
                            options: {},
                            start: {
                                name: 'start_time'
                            },
                            end: {
                                name: 'end_time'
                            },
                        },
                    ],
                },
                transfer_visible: false,
                transfer_form_data: {
                    title: "转移",
                    list: [{
                            type: 'text',
                            name: 'id',
                            hidden: true,
                            options: {},
                        },
                        {
                            type: "tree-select",
                            name: "pid",
                            title: "部门",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择部门"
                                }]
                            },
                            treeData: [],
                            multiple: false
                        }
                    ]
                },
                form_data: {
                    title: "添加",
                    list: [{
                            type: 'text',
                            name: 'id',
                            hidden: true,
                            options: {},
                        },
                        {
                            type: 'text',
                            name: 'pid',
                            hidden: true,
                            options: {},
                        },
                        {
                            type: "text",
                            name: "name",
                            title: "姓名",
                            options: {}
                        },
                        {
                            type: "text",
                            name: "name",
                            title: "销售单位",
                            options: {}
                        },
                        {
                            type: "text",
                            name: "name",
                            title: "出库单状态",
                            options: {}
                        },
                        {
                            type: "text",
                            name: "name",
                            title: "出库单生成时间",
                            options: {}
                        }
                    ]
                }
            };
        },
        created() {
            Promise.all([this.$method.get_department()]).then(res => {
                this.form_data_seo.list.forEach(item => {
                    if (item.name == "department_id") {
                        item.treeData = res[0];
                    }

                });
            });

        },
        methods: {
            add() {
                this.form_data.title = "添加"
                this.form_data.list.forEach(item => {
                    item.options.initialValue = '';
                })
                this.visible = true;
            },
            del(data) {
                delFramework({
                    data: {
                        id: data.id
                    },
                    info: true,
                }).then(res => {
                    this.$refs.list.get_list();
                })
            },
            edit(data) {
                outbound_order_push({
                    data: {
                        id: data.id
                    }
                }).then(res => {
                    this.$refs.list.get_list();
                })
            },
            transfer(data) {
                this.$keep_route.add(this.$options.name);
                this.$router.push({
                    path: '/distributor/details',
                    query: {
                        id: data.id
                    }
                })
            },
            transfer_submit({
                values
            }) {
                transferFramework({
                    data: {
                        ...values,
                    }
                }).then(res => {
                    this.transfer_visible = false;
                    this.$refs.list.get_list();
                })
            },
            area(record) {
                setRegion({
                    data: {
                        department_id: record.id,
                        status: record.region === 0 ? 1 : -1
                    },
                    info: true,
                }).then(res => {
                    this.$refs.list.get_list();

                })
            },
            submit(e) {
                if (!e.values.id) {
                    e.values.id = '';
                }
                changeFramework({
                    data: e.values,
                    info: true,
                }).then(res => {
                    this.visible = false;
                    this.$refs.list.get_list();
                })
            },
            download() {
                outbound_order_export({
					data: {
						...this.$refs.list.seo_data
					}
				}).then(res => {
					let a = document.createElement('a');
					a.href = res.url;
                    a.target = '_blank'
					a.click();
				})
            }

        }
    };
</script>
